<template>
  <div>
    <b-tabs>
      <b-tab
        active
        title="Partner"
      >
        <b-card-code
          title="Data Partner"
          no-body
        >
          <b-card-body>
            <div class="d-flex justify-content-between flex-wrap">
              <!-- sorting  -->
              <b-form-group
                label="Sort"
                label-size="sm"
                label-align-sm="left"
                label-cols-sm="3"
                label-for="sortBySelect"
                class="mr-1 mb-md-0"
              >
                <b-input-group size="sm">
                  <b-form-select
                    id="sortBySelect"
                    v-model="partner_table.sortBy"
                    :options="sortOptionsPartner"
                  >
                    <template #first>
                      <option value="">
                        none
                      </option>
                    </template>
                  </b-form-select>
                  <b-form-select
                    v-model="partner_table.sortDesc"
                    size="sm"
                    :disabled="!partner_table.sortBy"
                  >
                    <option :value="false">
                      ASC
                    </option>
                    <option :value="true">
                      DESC
                    </option>
                  </b-form-select>
                </b-input-group>
              </b-form-group>

              <!-- filter -->
              <b-form-group
                label="Filter"
                label-cols-sm="2"
                label-align-sm="left"
                label-size="sm"
                label-for="filterInput"
                class="mb-0"
              >
                <b-input-group size="sm">
                  <b-form-input
                    id="filterInput"
                    v-model="partner_table.filter"
                    type="search"
                    placeholder="Type to Search"
                  />
                  <b-input-group-append>
                    <b-button
                      :disabled="!partner_table.filter"
                      @click="partner_table.filter = ''"
                    >
                      Clear
                    </b-button>
                  </b-input-group-append>
                </b-input-group>
              </b-form-group>
            </div>
          </b-card-body>

          <b-table
            striped
            hover
            responsive
            show-empty
            class="position-relative"
            :per-page="partner_table.perPage"
            :current-page="partner_table.currentPage"
            :items="partner_table.items"
            :fields="partner_table.fields"
            :sort-by.sync="partner_table.sortBy"
            :sort-desc.sync="partner_table.sortDesc"
            :sort-direction="partner_table.sortDirection"
            :filter="partner_table.filter"
            :filter-included-fields="partner_table.filterOn"
            @filtered="onFilteredPartner"
          >
            <template #cell(name)="data">
              <b-avatar
                v-if="data.item.logo !== null"
                class="mr-50"
                :src="`${data.item.logo}`"
              />
              <b-avatar
                v-else
                class="mr-50"
                variant="primary"
                :text="data.item.name | initial"
              />
              {{ data.item.name }}
            </template>
          </b-table>

          <b-card-body class="d-flex justify-content-between flex-wrap pt-0">
            <!-- page length -->
            <b-form-group
              label="Per Page"
              label-cols="6"
              label-align="left"
              label-size="sm"
              label-for="sortBySelect"
              class="text-nowrap mb-md-0 mr-1"
            >
              <b-form-select
                id="perPageSelect"
                v-model="partner_table.perPage"
                size="sm"
                inline
                :options="partner_table.pageOptions"
              />
            </b-form-group>

            <!-- pagination -->
            <div>
              <b-pagination
                v-model="partner_table.currentPage"
                :total-rows="partner_table.totalRows"
                :per-page="partner_table.perPage"
                first-number
                last-number
                prev-class="prev-item"
                next-class="next-item"
                class="mb-0"
              >
                <template #prev-text>
                  <feather-icon
                    icon="ChevronLeftIcon"
                    size="18"
                  />
                </template>
                <template #next-text>
                  <feather-icon
                    icon="ChevronRightIcon"
                    size="18"
                  />
                </template>
              </b-pagination>
            </div>
          </b-card-body>
        </b-card-code>
      </b-tab>
      <b-tab title="Member">
        <b-card-code
          title="Data Partner"
          no-body
        >
          <b-card-body>
            <div class="d-flex justify-content-between flex-wrap">
              <!-- sorting  -->
              <b-form-group
                label="Sort"
                label-size="sm"
                label-align-sm="left"
                label-cols-sm="3"
                label-for="sortBySelect"
                class="mr-1 mb-md-0"
              >
                <b-input-group size="sm">
                  <b-form-select
                    id="sortBySelect"
                    v-model="member_table.sortBy"
                    :options="sortOptionsMember"
                  >
                    <template #first>
                      <option value="">
                        none
                      </option>
                    </template>
                  </b-form-select>
                  <b-form-select
                    v-model="member_table.sortDesc"
                    size="sm"
                    :disabled="!member_table.sortBy"
                  >
                    <option :value="false">
                      ASC
                    </option>
                    <option :value="true">
                      DESC
                    </option>
                  </b-form-select>
                </b-input-group>
              </b-form-group>

              <!-- filter -->
              <b-form-group
                label="Filter"
                label-cols-sm="2"
                label-align-sm="left"
                label-size="sm"
                label-for="filterInput"
                class="mb-0"
              >
                <b-input-group size="sm">
                  <b-form-input
                    id="filterInput"
                    v-model="member_table.filter"
                    type="search"
                    placeholder="Type to Search"
                  />
                  <b-input-group-append>
                    <b-button
                      :disabled="!member_table.filter"
                      @click="member_table.filter = ''"
                    >
                      Clear
                    </b-button>
                  </b-input-group-append>
                </b-input-group>
              </b-form-group>
            </div>
          </b-card-body>

          <b-table
            striped
            hover
            responsive
            show-empty
            class="position-relative"
            :per-page="member_table.perPage"
            :current-page="member_table.currentPage"
            :items="member_table.items"
            :fields="member_table.fields"
            :sort-by.sync="member_table.sortBy"
            :sort-desc.sync="member_table.sortDesc"
            :sort-direction="member_table.sortDirection"
            :filter="member_table.filter"
            :filter-included-fields="member_table.filterOn"
            @filtered="onFilteredPartner"
          >
            <template #cell(name)="data">
              <div class="d-flex flex-row">
                <b-avatar
                  v-if="data.item.image !== null"
                  class="mr-50 d-flex"
                  :src="`${data.item.image}`"
                />
                <b-avatar
                  v-else
                  class="mr-50 d-flex"
                  variant="primary"
                  :text="data.item.name | initial"
                />
                <div class="d-flex flex-column">
                  <p class="m-0">
                    {{ data.item.name }}
                  </p>
                  <small class="text-muted">
                    {{ `${data.item.email}` }}
                  </small>
                </div>
              </div>
            </template>
          </b-table>

          <b-card-body class="d-flex justify-content-between flex-wrap pt-0">
            <!-- page length -->
            <b-form-group
              label="Per Page"
              label-cols="6"
              label-align="left"
              label-size="sm"
              label-for="sortBySelect"
              class="text-nowrap mb-md-0 mr-1"
            >
              <b-form-select
                id="perPageSelect"
                v-model="member_table.perPage"
                size="sm"
                inline
                :options="member_table.pageOptions"
              />
            </b-form-group>

            <!-- pagination -->
            <div>
              <b-pagination
                v-model="member_table.currentPage"
                :total-rows="member_table.totalRows"
                :per-page="member_table.perPage"
                first-number
                last-number
                prev-class="prev-item"
                next-class="next-item"
                class="mb-0"
              >
                <template #prev-text>
                  <feather-icon
                    icon="ChevronLeftIcon"
                    size="18"
                  />
                </template>
                <template #next-text>
                  <feather-icon
                    icon="ChevronRightIcon"
                    size="18"
                  />
                </template>
              </b-pagination>
            </div>
          </b-card-body>
        </b-card-code>
      </b-tab>
    </b-tabs>
  </div>
</template>

<script>
import BCardCode from '@core/components/b-card-code/BCardCode.vue'
import {
  BTabs, BTab,
  BCardBody, BTable, BAvatar,
  BFormGroup, BFormSelect, BPagination, BInputGroup, BFormInput, BInputGroupAppend, BButton,
} from 'bootstrap-vue'

export default {
  components: {
    BTabs,
    BTab,
    BCardCode,
    BCardBody,
    BTable,
    BAvatar,
    BFormGroup,
    BFormSelect,
    BPagination,
    BInputGroup,
    BFormInput,
    BInputGroupAppend,
    BButton,
  },
  data() {
    return {
      partner_table: {
        perPage: 50,
        pageOptions: [10, 50, 100],
        totalRows: 1,
        currentPage: 1,
        sortBy: '',
        sortDesc: false,
        sortDirection: 'asc',
        filter: null,
        filterOn: [],
        fields: [
          { key: 'name', label: 'Partner', sortable: true },
          { key: 'total_redeem', label: 'Total Redeem', sortable: true },
        ],
        items: [],
      },
      member_table: {
        perPage: 10,
        pageOptions: [10, 50, 100],
        totalRows: 1,
        currentPage: 1,
        sortBy: '',
        sortDesc: false,
        sortDirection: 'asc',
        filter: null,
        filterOn: [],
        fields: [
          { key: 'name', label: 'User Name', sortable: true },
          { key: 'partner', label: 'Partner', sortable: true },
          { key: 'periode_text', label: 'Tanggal', sortable: true },
        ],
        items: [],
      },
    }
  },
  computed: {
    sortOptionsPartner() {
      // Create an options list from our fields
      return this.partner_table.fields
        .filter(f => f.sortable)
        .map(f => ({ text: f.label, value: f.key }))
    },
    sortOptionsMember() {
      // Create an options list from our fields
      return this.member_table.fields
        .filter(f => f.sortable)
        .map(f => ({ text: f.label, value: f.key }))
    },
  },
  created() {
    this.getDetailPartner()
  },
  methods: {
    onFilteredPartner(filteredItems) {
      // Trigger pagination to update the number of buttons/pages due to filtering
      this.partner_table.totalRows = filteredItems.length
      this.partner_table.currentPage = 1
    },
    onFilteredMember(filteredItems) {
      // Trigger pagination to update the number of buttons/pages due to filtering
      this.member_table.totalRows = filteredItems.length
      this.member_table.currentPage = 1
    },

    async getDetailPartner() {
      const { id } = this.$route.params
      const cid = localStorage.getItem('client_id')
      let dataAPI = null
      const config = {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('token')}`,
        },
      }
      dataAPI = await this.$axios.get(`${cid}/partner/corporate/${id}`, config)

      const { partner } = dataAPI.data.data
      const { member } = dataAPI.data.data

      // add data and total row partner
      this.partner_table.items = partner
      this.partner_table.totalRows = partner.length

      // add data and total row member
      this.member_table.items = member
      this.member_table.totalRows = member.length
    },
  },
}
</script>
